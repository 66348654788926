<template>
  <div class="readme-article">
    <div id="招生"></div>
    <h1 id="线索资料管理">
      一、线索资料管理
    </h1>
    <h2 id="招生线索查询">1.招生线索查询</h2>
    <h3 id="线索列表">1.1线索列表</h3>
    <p>记录已经入库的所有招生线索，可通过关键词，来源渠道，时间等条件查询线索，持续的跟进可促进线索转化为正式学员</p>
    <p>
      <img src="@/assets/img/recruit/1-1.png">
    </p>
    <h3 id="线索操作">1.2线索操作</h3>
    <p>可根据线索情况进行线索详情查看，详情资料编辑，提交转正申请以及删除等操作，线索详情上方也有一些相应操作，例如切换校区，放入公海，锁定等</p>
    <p>
      <img src="@/assets/img/recruit/1-2.png">
    </p>
    <h3 id="线索回访">1.3线索回访</h3>
    <p>线索列表的回字可新增回访记录，记录下次联系时间，联系内容</p>
    <p>
      <img src="@/assets/img/recruit/1-3.png">
    </p>
    <h2 id="添加线索资料">2.添加线索资料</h2>
    <p>获取到线索信息后可手动添加到招生线索中，记录线索信息，一体化跟进回访，提高成交率</p>
    <p>
      <img src="@/assets/img/recruit/1-4.png">
    </p>
    <h2 id="已转正线索">3.已转正线索</h2>
    <p>显示已转正为正式学员的线索列表</p>
    <p>
      <img src="@/assets/img/recruit/1-5.png">
    </p>
    <h2 id="线索公海">4.线索公海</h2>
    <h3 id="公海概述">4.1公海概述</h3>
    <p>线索公海又指公共线索，当前在跟进的线索可手动放入公海或在满足设定的规则时自动掉入公海，所有员工都可以在公海中领取线索，成为该线索的新负责人</p>
    <p>
      <img src="@/assets/img/recruit/1-6.png">
    </p>
    <h3 id="公海规则调配">4.2公海规则调配</h3>
    <p>线索公海规则需要先行配置，配置位置：招生-->调配规则-->线索回收规则/线索领取规则/线索锁定规则</p>
    <p>
      <img src="@/assets/img/recruit/1-7.png">
    </p>
    <h2 id="N天未联系">5.N天未联系</h2>
    <p>显示多天未联系的招生线索列表，可通过未联系天数和其他条件进行筛选</p>
    <p>
      <img src="@/assets/img/recruit/1-8.png">
    </p>
    <h2 id="超时未回访">6.超时未回访</h2>
    <p>显示已经超过预约时间没有回访的线索列表</p>
    <p>
      <img src="@/assets/img/recruit/1-9.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Recruit1-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>